import {
  LitElement, html, css, customElement, property
} from 'lit-element';

import { default as QRCode } from 'qrcode';

import '@ugetid/dynamic-form';

export type MenuEntry = {
  label: string;
  gid?: Array<string>;
  page?: string;
  url?: string;
  target?: string,
  subpages?: Array<MenuEntry>;
  icon?: string;
}

@customElement('evo-config')
export class EvoConfig extends LitElement {
  _version_ = "1.0.0";
  /***
   * Observed properties. Triggers update on change.
   */
  @property({type: String})
  _title = "";
  public get title(): string { return this._title; };
  public set title(value: string) { this._title = value || ""; document.title = this.locales[this.locale]?.[this._title] ?? this._title; };

  @property({type: Boolean})
  sidebar = true;
  update_interval = 1 * 1000;

  getAuthUrl    = '/api/v3/AccessId/oauth2_start';
  getDataUrl    = '/api/v3/AccessId/get_config_form';
  getLocalesUrl = '/api/v3/AccessId/get_locales';
  getPermissionsUrl = '/api/v3/AccessId/get_config_user';
  getStyleVariablesUrl = '/assets/styles/variables.css';

  @property({type: Element})
  form: any = null;
  modal: HTMLDivElement | null | undefined = null;

  @property({type: Boolean})
  formColoredBackground: Boolean | null = null;

  @property({type: String})
  loadingText = ""
  locks: Record<string, string> = {}

  @property({type: String})
  error: string = "";

  @property({type: Array})
  form_items = [];

  @property({type: String})
  token: string = localStorage.getItem('config.token') ?? this.newAuthorization();

  @property({type: Object})
  user: Record<string, string> = {};

  page: string = "";
  defaultPages: Array<string> = ["home", "visitor_review"];

  @property({type: Object})
  locales: Record<string, Record<string, string>> = {};

  @property({type: Object})
  permissions: Record<string, MenuEntry> = {};

  @property({type: String})
  locale = localStorage.getItem('locale') ?? 'pt';

  i18n(str: string, missing?: string): string {
    return this.locales[this.locale]?.[str] ?? missing;
  }

  i18nSafe(str: string): string {
    return this.i18n(str, str);
  }


  @property({type: Object})
  printer = (() => {
    let printer = (window as any).printer ?? {
      printText: () => {},
      printCenterText: () => {},
      printTable: () => {},
      // printQRCode: () => {},
      printQRCode: this.showQRCode.bind(this),
    }
    delete (window as any).printer;
    return printer;
  })();

  defaultStyleVariables = `
    html {
      --primary-color:   #1D1D1B;  /* #007E7A */
      --secondary-color: #ECB122;  /* #ECB11F */
      --tertiary-color:  #7B7B7B;  /* #747678 */
      --quarternary-color: #D01645;
      --quinary-color: #fff;
      --senary-color: rgba( 0, 0, 0, 0.7 );
      --sidebar-color: #FFFFFF;
      --sidebar-text-color: var(--tertiary-color);
      --sidebar-bubble-color: var(--primary-color);
      --sidebar-bubble-text-color: #FFFFFF;
      --form-background-color: #EFEFEF;
      --button-next-color: #009B3A;
      --button-back-color: var(--quarternary-color);
      --button-cancel-color: var(--quarternary-color);
      --button-color: var(--secondary-color);
      --button-text-color: #FFF;
      --button-yes-color: #009B3A;
      --button-no-color: var(--quarternary-color);
      --button-menu-color: #fff;
      --button-secondary-color: var(--tertiary-color);
      --button-menu-text-color: #1D1D1B;
      --button-position-top: auto;
      --button-position-right: auto;
      --button-position-left: auto;
      --button-position-bottom: 5vh;
      --button-totem-height: 80px;
      --button-totem-menu-height: 13vh;
      --totem-font-family: Roboto, Arial, sans-serif;
      --totem-font-weight: 700;
      --totem-font-size: 1.2em;
      --totem-line-height: 2em;
      --totem-padding-left: 20px;
      --totem-padding-right: 20px;
      --totem-button-width: 90%;
      --totem-button-margin: 16px;
      --totem-button-border-radius: 15px;
      --totem-button-box-shadow: 8px 16px 9px rgba(0, 0, 0, 0.25);
      --lumo-primary-text-color: var(--primary-color);
    }
  `

  static get styles() {
    return css`
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    /*===================================
        base
    ====================================*/

     :host {
      overflow-x: hidden;
    }

    .container {
        padding: 80px 45px 45px 45px;
        box-shadow: rgb(0 0 0 / 10%) 0px 5px 10px 0px;
    }

    .form.always-colored-background {
      background-color: var(--form-background-color);
      border-radius: 10px;
      padding: 30px;
    }

    @media (max-width: 768px) {
        .container {
            padding: 30px 15px 15px 15px;
        }
        .container.no-sidebar {
            padding: 90px 45px 45px 45px;
        }
    }

    .grid-layout {
        font-family: Roboto, Arial, sans-serif;
        font-size: 16px;
        color: var(--tertiary-color);
        line-height: 1.2;
    }

    a {
        color: var(--primary-color);
        cursor: pointer;
    }

    a:hover {
        color: var(--primary-color);
    }

    .m-0 {
        margin: 0 !important;
    }

    .mt-15 {
        margin-top: 15px !important;
    }

    .mr-15 {
        margin-right: 15px !important;
    }

    .mb-15 {
        margin-bottom: 15px !important;
    }

    .ml-15 {
        margin-left: 15px !important;
    }

    .mt-30 {
        margin-top: 30px !important;
    }

    .mr-30 {
        margin-right: 30px !important;
    }

    .mb-30 {
        margin-bottom: 30px !important;
    }

    .ml-30 {
        margin-left: 30px !important;
    }

    .mt-60 {
        margin-top: 60px !important;
    }

    .mr-60 {
        margin-right: 60px !important;
    }

    .mb-60 {
        margin-bottom: 60px !important;
    }

    .ml-60 {
        margin-left: 60px !important;
    }


    /*===================================
    header
    =====================================*/

    .header {
        height: 100px;
        position: relative;
        padding: 20px 90px;
        width: 100%;
        background-color: var(--quinary-color);
        -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    }

    #sidebar-trigger {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        width: 60px;
        height: 60px;
        background-color: var(--quinary-color);
        padding: 12px 15px;
        cursor: pointer;
        transition: left 600ms cubic-bezier(.20, .70, .40, .90);
    }

    #sidebar-trigger span {
        display: block;
        width: 25px;
        height: 2px;
        background-color: var(--tertiary-color);
        margin: 7px 0;
    }

    #sidebar-trigger.active {
        position: fixed;
        left: calc(100% - 60px);
        -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    }
    .logo {
        height: 60px;
    }

    @media (max-width: 768px) {
        .header {
            height: 60px;
            padding: 0px 30px;
            text-align: center;
        }
        .logo {
            width: 150px;
        }
        #sidebar-trigger {
            display: block;
        }
    }


    /*===================================
    grid
    =====================================*/
    .header {
        grid-area: header;
    }
    .sidebar {
        grid-area: sidebar;
        margin-top: -5px;
    }
    .content {
        grid-area: content;
    }

    .grid-layout {
      position: relative;
      width: 100%;
      min-height: 100vh;
      margin-right: auto;
      margin-left: auto;
      display: grid;
      grid-template-columns: 360px auto;
      grid-template-rows:105px auto;
      grid-template-areas:
      "header header"
      "sidebar content";
    }

    .grid-layout.grid-layout--one-col {
      justify-items: center;
      grid-template-columns: 1fr;
      grid-template-rows:105px auto;
      grid-template-areas:
      "header"
      "content"
    }

    @media (max-width: 768px) {
      .grid-layout {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows:60px auto auto;
        grid-template-areas:
        "header"
        "sidebar"
        "content";
      }
      .grid-layout.grid-layout--one-col {
        justify-items: center;
        grid-template-rows:90px auto;
        grid-template-areas:
        "header"
        "content"
      }
    }


    /*===================================
        aside grid
    =====================================*/

    .sidebar-1 {
        grid-area: sidebar-1;
    }

    .sidebar-2 {
        grid-area: sidebar-2;
    }

    .sidebar-5 {
        grid-area: sidebar-5;
        padding-left: 30px;
    }

    .sidebar-6 {
        grid-area: sidebar-6;
        padding-left: 30px;
    }

    .sidebar-7 {
        grid-area: sidebar-7;
        padding-left: 5px;
    }

    .sidebar-8 {
        grid-area: sidebar-8;
        padding-left: 5px;
    }

    .sidebar {
      background: var(--primary-color);
      position: relative;
      padding: 60px 0 30px 30px;
      display: grid;
      align-content: start;
      row-gap: 15px;
        box-shadow: rgb(0 0 0 / 10%) 0px 10px 0px 0px;        
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows:auto;
      grid-template-areas:
      "sidebar-1 sidebar-1 sidebar-1 sidebar-1"
        "sidebar-5 sidebar-5 sidebar-5 sidebar-5"
        "sidebar-6 sidebar-7 sidebar-8 ."
      "sidebar-2 sidebar-2 sidebar-2 sidebar-2"
    }

    @media (max-width: 768px) {
      .sidebar {
        position: fixed;
        z-index: 3;
        top: 0;
        bottom: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        min-height: 100%;
        padding: 0 0 30px 60px;
        margin-top: -5px;
        transition: left 600ms cubic-bezier(.20,.70,.40,.90);
        overflow: auto;
      }
      .sidebar.active {
        position: fixed;
        left: -60px;
      }
    }


    /*===================================
        aside elements
    =====================================*/

    .sidebar__user {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        gap: 15px;
        text-transform: uppercase;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 15px;
    }

    .sidebar__user p {
      flex-grow: 1;
    }

    .sidebar__user span {
        display: block;
        font-size: 50px;
        color: var(--primary-color);
    }

    @media (max-width: 768px) {
      .sidebar__user {
         padding-top: 20px;
       }
    }

    /*navmenu grids*/

    .nav-grid-item-1 {
        grid-area: nav-grid-item-1;
        font-size: 18px;
        margin-top: -3px;
    }

    .sidebar ul li p {
        grid-area: nav-grid-item-2;
        margin: 0;
    }

    .sidebar .has-sub-items a:after {
        grid-area: nav-grid-item-3;
    }

    .sidebar .sub-items a:after {
        grid-area: nav-grid-item-4;
    }

    .sidebar>ul>li>a,
    .sidebar>ul>li>ul>li>a {
        display: grid;
        align-content: center;
        align-items: center;
        column-gap: 10px;
        grid-template-columns: 20px auto 20px;
        grid-template-rows: auto;
        grid-template-areas: "nav-grid-item-1 nav-grid-item-2 nav-grid-item-3";
        color: var(--tertiary-color);
    }

    .sidebar>ul>li>ul>li>a {
        grid-template-areas: ". nav-grid-item-2 nav-grid-item-4";
    }


    /*general ul styles*/

    .sidebar ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .sidebar>ul>li {
        position: relative;
        display: block;
        padding: 10px 15px 10px 30px;
    }

    .sidebar>ul>li>ul>li {
        position: relative;
        display: block;
        padding: 15px 0 0 0;
    }


    /*if parent has sub items*/


    .sidebar .has-sub-items>a:after {
        font-family: 'icomoon';
        content: "\\e912";
        font-size: 16px;
    }

    .sidebar .has-sub-items {
        transition: all 250ms;
    }

    .sidebar .has-sub-items.opened {
        background: var(--secondary-color);
        border-radius: 10px 0px 0px 10px;
    }

    .sidebar .has-sub-items.opened>a:after {
        content: "\\e911";
    }

    .sidebar .has-sub-items.opened a,
    .sidebar .has-sub-items.opened a>* {
        color: var(--primary-color) !important;
    }

    .sidebar .has-sub-items ul {
        opacity: 0;
        max-height: 0;
        transition: all 250ms;
    }

    .sidebar .has-sub-items.opened ul {
        opacity: 1;
        max-height: 500px;
    }


    /*sub item arrow*/

    /*
    .sidebar .sub-items .active>a:after {
        font-family: 'icomoon';
        content: "\\e902";
        font-size: 24px;
    }
    */


    /*language*/

    .language-flag.activate {
        opacity: 1;
    }

    .language-flag {
        opacity: 0.5;
    }


    /*language*/

    .language-flag {
        opacity: 0.5;
        width: 70px;
    }

    .language-flag.activate {
        opacity: 1;
    }

    @media (max-width: 768px) {
        .language-flag,
        .language-flag.activate {
            max-width: 50px;
        }
    }

    @media (max-width: 768px) {
        .language-flag,
        .language-flag.activate {
            max-width: 35px;
        }
    }


    /**/


    /*===========================================
        typography
    ============================================*/

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: normal;
        font-style: normal;
        text-rendering: optimizeLegibility;
    }

    h1 {
        font-family: Roboto, Arial, sans-serif;
        font-size: 24px;
        font-weight: 300;
        color: var(--tertiary-color);
        margin: 0;
    }

    h2 {
        font-family: Roboto, Arial, sans-serif;
        font-size: 20px;
        font-weight: 300;
        color: var(--quinary-color);
        margin: 0;
    }

    p {
        font-family: Roboto, Arial, sans-serif;
        font-size: 16px;
        font-weight: 300;
        color: var(--quinary-color);
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 14px;
        }
        h2 {
            font-size: 20px;
        }
        p {
            font-size: 14px;
        }
    }

    .title-01 {
        font-family: Roboto, Arial, sans-serif;
        font-size: 24px;
        font-weight: 300;
        color: var(--tertiary-color);
        margin: 0;
    }

    .ft-reg {
        font-family: Roboto, Arial, sans-serif;
        font-size: 16px;
        font-weight: 400;
    }

    .ft-gray {
        color: var(--tertiary-color);
    }

    .ft-white {
        color: var(--quinary-color);
    }

    .ft-yellow {
        color: var(--secondary-color);
    }

    .ft-green {
        color: var(--primary-color);
    }


    /**************************************************/
    /*
          :host {
            display: block;
            --material-primary-color: var(--primary-color);
            --material-primary-text-color: var(--secondary-color);
            --material-primary-contrast-color: var(--secondary-color);
          }
    ***************************************************/

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s ease;
        transition: visibility 0.2s ease;
    }

    .menu.open~.overlay,
    .dialog.open~.overlay,
    .overlay.loading {
        visibility: visible;
        opacity: 1;
        background-color: var(--senary-color);
    }

    .menu {
        transition: left 0.2s ease;
        position: absolute;
        height: 100vh;
        width: 70vmin;
        top: 0;
        left: -100%;
        font-size: 1.5em;
        background-color: var(--quinary-color);
        z-index: 1;
    }

    .menu.open {
        left: 0;
    }

    .menu_header {
        height: 64px;
        position: relative;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .menu_header_row {
        display: flex;
        flex-direction: row;
        line-height: 40px;
    }

    .menu_item {
        margin: 16px;
    }

    .menu_item.selected {
        font-weight: bold;
    }


    /***********************************************************/
    /* icons */
    /***********************************************************/

    [class^="icon-"],
    [class*=" icon-"] {
        color: var(--quinary-color);
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .icon-arrow-down::before {
      content: "\\e900";
    }
    .icon-arrow-left::before {
      content: "\\e901";
    }
    .icon-arrow-right::before {
      content: "\\e902";
    }
    .icon-arrow-up::before {
      content: "\\e903";
    }
    .icon-mail::before {
      content: "\\e904";
    }
    .icon-padlock::before {
      content: "\\e905";
    }
    .icon-whatsapp::before {
      content: "\\e906";
    }
    .icon-close::before {
      content: "\\e907";
    }
    .icon-photo::before {
      content: "\\e908";
    }
    .icon-delete::before {
      content: "\\e909";
    }
    .icon-edit::before{
      content: "\\e90a";
    }
    .icon-add::before{
      content: "\\e90b";
    }
    .icon-auth::before{
      content: "\\e90c";
    }
    .icon-customization::before{
      content: "\\e90d";
    }
    .icon-home::before{
      content: "\\e90e";
    }
    .icon-user::before{
      content: "\\e90f";
    }
    .icon-logout::before{
      content: "\\e910";
    }
    .icon-minus::before{
      content: "\\e911";
    }
    .icon-plus::before{
      content: "\\e912";
    }
    .icon-search::before{
      content: "\\e913";
    }
    .icon-car::before{
      content: "\\e914";
    }
    .icon-tablet::before{
      content: "\\e915";
    }
    .icon-visitor::before{
      content: "\\e916";
    }

    /***********************************************************/

    .overlay-modal {
      display:    none;
      position:   fixed;
      z-index:    1000;
      top:        0;
      left:       0;
      height:     100%;
      width:      100%;
      background-color: var(--senary-color);
      overscroll-behavior: contain;
    }
    .overlay-modal.loading {
      display: flex;
    }

    .overlay-modal-box {
      display: flex;
      align-self: center;
      margin: 0 auto;

      padding: var(--loading-modal-padding);
      border-radius: var(--loading-modal-border-radius);
      background-color: var(--loading-modal-color);
      color: var(--loading-modal-text-color);
      text-align: var(--loading-modal-text-align);
      text-transform: var(--loading-modal-text-transform);
      font-family: var(--loading-modal-font-family);
      font-size: var(--loading-modal-font-size);
      font-weight: var(--loading-modal-font-weight);
      line-height: var(--loading-modal-line-height);

      flex-direction: column;
    }

    .overlay-modal img {
      margin: 0 auto;
      object-fit: none;
    }

    .settings-icon img {
      filter: invert(1);
    }

    .user-name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    `;
  }

  render(){
    return html`
      <div class="grid-layout ${this.sidebar ? '' : 'no-sidebar'}">
        <header class="header">
          <div id="sidebar-trigger" @click="${this.triggerMenuMobile}">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <img class="logo" src="/assets/images/company/product_logo.png" alt="AccessId">
        </header>

        <aside id="sidebar" class="sidebar ${this.sidebar ? '' : 'no-sidebar'}">
          <div class="sidebar-1 sidebar__user">
            <img src="/assets/images/company/avatar.png" alt="user" width="70">
            <!--<span class="icon-user"></span>-->
            <p class="user-name">${this.i18nSafe('hello' ?? 'loading')}, ${this.user.name}</p>
            <a class="settings-icon" @click="${() => this.loadPage("settings")}">
              <img src="/assets/images/icon/settings.svg" alt="settings" width="25">
            </a>
          </div>

          <h2 class="sidebar-5">${this.i18nSafe('change_language' ?? "loading")}</h2>

          <a class="sidebar-6">
            <img class="language-flag" id="flag-pt" @click="${() => this.changeLocale('pt')}" src="/assets/images/flag/pt.png">
          </a>

          <a class="sidebar-7">
            <img class="language-flag" id="flag-en" @click="${() => this.changeLocale('en')}" src="/assets/images/flag/en.png">
          </a>

          <a class="sidebar-8">
            <img class="language-flag" id="flag-es" @click="${() => this.changeLocale('es')}" src="/assets/images/flag/es.png">
          </a>

          <ul class="sidebar-2">
            ${Object.entries(this.permissions).map( (permission, index) => {
              let [page, subitens] = permission;
              if (subitens.gid && subitens.gid.indexOf(this.user.gid) === -1) {
                return html``;
              }
              if (!subitens.subpages) {
                return html `
                  <li>
                    <a @click="${() => {
                        if (subitens.page) {
                          this.loadPage(subitens.page);
                        } else if (subitens.url) {
                          if (subitens.target) {
                            window.open(subitens.url, subitens.target);
                          } else {
                            window.location.href = subitens.url;
                          }
                        }
                      }}">
                      <span class="nav-grid-item-1 ${subitens.icon}"></span>
                      <p>${this.i18nSafe(subitens.label ?? subitens.page ?? '')}</p>
                    </a>
                  </li>
                `
              } else {
                return html`
                  <li class="has-sub-items ${ index === 0 ? 'opened' : ''}">
                    <a @click="${(e: Event) => {
                        if (subitens.page) {
                          this.loadPage(subitens.page);
                        } else if (subitens.url) {
                          if (subitens.target) {
                            window.open(subitens.url, subitens.target);
                          } else {
                            window.location.href = subitens.url;
                          }
                        } else {
                          this.openSubmenu(e);
                        }
                      }}">
                      <span class="nav-grid-item-1 ${subitens.icon}"></span>
                      <p>${this.i18nSafe(subitens.label ?? page ?? '')}</p>
                    </a>

                    <ul class="sub-items">
                      ${subitens.subpages?.map(element => {
                        if (!element.gid || element.gid.indexOf(this.user.gid) !== -1) {
                          return html`
                            <li class="active">
                              <a @click="${() => {
                                  if (element.page) {
                                    this.loadPage(element.page);
                                  } else if (element.url) {
                                    if (subitens.target) {
                                      window.open(subitens.url, subitens.target);
                                    } else {
                                      window.location.href = element.url;
                                    }
                                  }
                                }}">
                                <p>${this.i18nSafe(element.label ?? element.page ?? 'loading')}</p>
                              </a>
                            </li>
                          `
                        } else {
                          return html``;
                        }
                      })}
                    </ul>
                  </li>
                `
              }
            })}

            <li>
              <a @click="${() => location.href='/api/v3/AccessId/oauth2_logout?token=' + this.token}">
                <span class="nav-grid-item-1 icon-logout"></span>
                <p>${this.i18nSafe('exit' ?? 'loading')}</p>
              </a>
            </li>

          </ul>
          </aside>

        <div class="container ${this.sidebar ? '' : 'no-sidebar'}">
          <dynamic-form class="form content ${this.formColoredBackground ? "always-colored-background" : ""}" .i18n="${this.i18n.bind(this)}" .i18nSafe="${this.i18nSafe.bind(this)}" .token="${this.token}" .lang="${this.locale}" @change="${this.formChange}"></dynamic-form>
        </div>
      </div>

      <div class="dialog">
        <dynamic-form class="form content" .i18n="${this.i18n.bind(this)}" .i18nSafe="${this.i18nSafe.bind(this)}" .token="${this.token}" .lang="${this.locale}" @change="${this.formChange}"></dynamic-form>
      </div>

      <div class="overlay-modal">
        <div class="overlay-modal-box">
          <img src="/assets/images/ui/loading.gif"></img>
          <span>&nbsp;${this.i18nSafe(this.loadingText).split("\n").map(s => html`${s}<br>`)}</span>
        </div>
      </div>
    `;
  }

  async triggerMenuMobile(): Promise<void> {
    let sidebar = this.shadowRoot?.querySelector('.sidebar') as HTMLElement;
    sidebar?.classList.toggle('active');

    let sidebarTrigger = this.shadowRoot?.querySelector('#sidebar-trigger') as HTMLElement;
    sidebarTrigger?.classList.toggle('active');
  }

  get updateCompleteRecursive(): Promise<boolean> {
    return this.updateComplete.then(async (_value: unknown) => {
      let value = _value as boolean;
      while (value === false) {
        value = await this.updateComplete as boolean;
      }
      return value;
    });
  }

  async firstUpdated(): Promise<void> {
    this.form = this.shadowRoot?.querySelector(".form");
    this.modal = this.shadowRoot?.querySelector('.overlay-modal');

    let langCurrent = `#flag-${this.locale}`;
    let imgCurrent = this.shadowRoot?.querySelector(langCurrent) as HTMLImageElement | null;
    if (imgCurrent) {
      imgCurrent.classList.toggle('activate');
    }

    try {
      let r = await fetch(this.getStyleVariablesUrl,{method: "GET",});
      let style = await r.text();
      // Doesn't work in Safari...
      // let css = new CSSStyleSheet();
      // css.replace(style);
      // document.adoptedStyleSheets.push(css);
      let css = document.createElement("style");
      css.innerHTML = style;
      document.documentElement.appendChild(css);
    } catch (error) {
      let css = document.createElement("style");
      css.innerHTML = this.defaultStyleVariables;
      document.documentElement.appendChild(css);
    }

    if (this.token != "") {
      if (!(await this.getUserInfo())) {
        this.newAuthorization();
      };
      await this.updateLocales();
    }

    this.form.addEventListener('pagechange', (e: any) => {
      this.title = e.detail.title ?? e.detail.label ?? e.detail.name ?? "";
      this.formColoredBackground = e.detail.form_colored_background ?? null;
    });
    this.form.addEventListener('lock', (e: any) => {
      this.locks[e.detail?.name] = e.detail?.loadingText ?? "";
      if (!this.loadingText) {
        if (this.locks[e.detail?.name]) {
          this.loadingText = this.locks[e.detail?.name];
        } else {
          this.loadingText = Object.values(this.locks).find(e => e) ?? "";
        }
      }
      this.modal?.classList.add('loading');
    });
    this.form.addEventListener('unlock', (e: any) => {
      let change = this.locks[e.detail?.name];
      delete this.locks[e.detail?.name];
      if (change) {
        this.loadingText = Object.values(this.locks).find(e => e) ?? "";
      }
      if (Object.keys(this.locks).length == 0) {
        this.modal?.classList.remove('loading');
      }
    });

    page_loading:
    for (let page of Object.values(this.permissions)) {
      if (page.page && this.loadPage(page.page)) {
        break;
      }
      if (page.subpages) {
        for (let subpage of page.subpages) {
          if (subpage.page && this.loadPage(subpage.page)) {
            break page_loading;
          }
        }
      }
    }
  }

  async getUserInfo(): Promise<boolean> {
    try {
      let r = await fetch(this.getPermissionsUrl, {
        method: "POST",
        body: JSON.stringify({token: this.token}),
      });
      let result = await r.json();

      if (!result.result) {
        return false;
      }
      this.user.name = result.user.name;
      this.user.gid = result.user.gid;
      this.permissions = result.pages;
      return true;
    } catch(exc) {
      console.error(exc);
    }
    return false;
  }

  newAuthorization(): string {
    fetch(this.getAuthUrl, {
      method: "POST",
    }).then(async (r) => {
      let result = await r.json();
      if (!result.result || !result.url) {
        // TODO: Show token expiration or something
        throw "invalid_token";
      }
      // Redirect to oauth2 url
      window.location.href = result.url;
    }).catch((e)=>{
      // TODO: Show error json form
      console.log("Auth error:", e);
    });
    return "";
  }

  async loadPage(page: string): Promise<boolean> {
    if (this.page == page) {
      return false;
    }
    try {
      let r = await fetch(this.getDataUrl, {
        method: "POST",
        body: JSON.stringify({token: this.token, page: page}),
      });
      let result = await r.json();
      if (!result.result) {
        await this.newAuthorization();
        return false;
      }
      if (!result.value) {
        result.value = {};
      }
      result.value.user = this.user;

      this.form.items = [];
      this.form.requestUpdate();
      await this.form.updateCompleteRecursive;
      this.form.reset();
      this.form.requestUpdate();
      await this.form.updateCompleteRecursive;

      this.form.value = result.value;
      await this.form._init;
      this.form.items = result.form;
      this.triggerMenuMobile();
      this.page = page;
    } catch(e) {
      // TODO: Show error json form
      return false;
    }
    return true;
  }

  async updated(): Promise<void> {
  }

  async changeLocale(lang?: string): Promise<void> {
    if (lang === this.locale)
      return;
    else {
      let langCurrent = `#flag-${lang}`;
      let langBefore = `#flag-${this.locale}`;

      let imgCurrent = this.shadowRoot?.querySelector(langCurrent) as HTMLImageElement;
      imgCurrent.classList.toggle('activate');

      let imgBefore = this.shadowRoot?.querySelector(langBefore) as HTMLImageElement;
      imgBefore.classList.remove('activate');
    }
    localStorage.setItem("locale", this.updateLocale(lang));
  }

  async updateLocales(): Promise<void> {
    // let r = await fetch("/assets/locales.json");
    let r = await fetch(this.getLocalesUrl,{
                          method: "POST",
                        });
    this.locales = await r.json();
    this.updateLocale();
  }

  updateLocale(lang?: string): string {
    if (lang == this.locale) {
      return this.locale;
    }
    if (lang && lang in this.locales) {
      this.locale = lang;
      this.form.value = {"lang": this.locale};
      return this.locale;
    }
    if (this.locale in this.locales) {
      return this.locale;
    }
    let locale = null;
    for (let lang of navigator.languages) {
      if (lang in this.locales) {
        locale = lang;
      }
    }
    this.locale = locale ?? 'pt';
    this.form.value = {"lang": this.locale};
    return this.locale;
  }

  openSubmenu(event: Event): void {
    (event.target as any)?.closest('.has-sub-items').classList.toggle('opened');
  }

  async showQRCode(payload: string, errorLevel: QRCode.QRCodeErrorCorrectionLevel = "H"): Promise<void> {
    console.log("QR", payload, await QRCode.toDataURL(payload, {errorCorrectionLevel: errorLevel}));
  }

  async formChange(): Promise<void> {
    if ("language" in this.form.value && this.locale != this.form.value.language) {
      localStorage.setItem("locale", this.updateLocale(this.form.value.language));
    }
  }
}
